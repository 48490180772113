.bg-color-main {
    background-color: var(--prussian-blue);
    color: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 5rem;
}

.subtitle {
    font-size: 1.5rem
}

.highlight {
    color: var(--main-button-color)
}