@tailwind base;
@tailwind components;
@tailwind utilities;

/*Fonts*/
/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"); */

:root {
  --black: #000;
  --white: #fff;
  --main-button-color: #3f51b5;
  font-size: max(16px, 0.8vw);

  /*Style Guide*/
  /*
    The following variables and typography styles are adapted from designer's style guide.
    Please change these only if there is a change in original design style or if the original style is not implemented properly
  */
  /*Brand Colors*/
  --prussian-blue: #003366;
  --prussian-blue-50: #8099b3;
  --prussian-blue-40: #99adc2;
  --prussian-blue-30: #b3c2d1;
  --prussian-blue-20: #ccd6e0;
  --prussian-blue-10: #e6ebf0;

  --blue-de-france: #2288ee;
  --blue-de-france-50: #91c4f7;
  --blue-de-france-40: #a7cff8;
  --blue-de-france-30: #bddbfa;
  --blue-de-france-20: #d3e7fc;
  --blue-de-france-10: #e9f3fd;

  --sky-blue-crayola: #22ddff;
  --sky-blue-crayola-50: #91eeff;
  --sky-blue-crayola-40: #a7f1ff;
  --sky-blue-crayola-30: #bdf5ff;
  --sky-blue-crayola-20: #d3f8ff;
  --sky-blue-crayola-10: #e9fcff;

  /* --black: #000; */
  --black-50: #808080;
  --black-40: #999;
  --black-30: #b3b3b3;
  --black-20: #ccc;
  --black-10: #e6e6e6;

  /* --green  */
  --green-dark: #2bac52;
  --green-light: #e9fdef;

  /* --red  */
  --red-dark: #f8795c;
  --red-light: #fff5f2;

  /* --purple */
  --purple-dark: #7b61ff;
  --purple-light: #f4f2fe;

  /*Text*/

  --text-primary: var(--black);
  --text-secondary: var(--black-50);
  --text-secondary-2: var(--black-30);
  --text-border: var(--black-10);
  --base: var(--white);

  /*Messages*/

  --error: #c82020;
  --success: #09b746;
  --warning: #ffbc27;

  /*Icons*/
  --blue: var(--prussian-blue);
  --light-blue: var(--blue-de-france);
  --gray: var(--black-30);

  /*Backgrounds*/
  --background: #fafafb;

  /*Primary Button*/

  --primary-button-text: var(--white);
  --primary-button-background: var(--prussian-blue);
  --primary-button-on-hover-text: var(--white);
  --primary-button-on-hover-background: #094989;
  --primary-button-disabled-text: var(--prussian-blue-30);
  --primary-button-disabled-background: var(--prussian-blue-10);

  /*secondary Button*/
  --secondary-button-text: var(--white);
  --secondary-button-background: var(--blue-de-france);
  --secondary-button-on-hover-text: var(--white);
  --secondary-button-on-hover-background: #1478dc;
  --secondary-disabled-text: var(--blue-de-france-30);
  --secondary-disabled-background: var(--blue-de-france-10);

  /*Forms*/

  --form-label: var(--black);
  --form-default-input-text: var(--prussian-blue-30);
  --form-placeholder-text: var(--black);
  --form-border: #d8d7dc;
  --form-border-on-hover: var(--blue-de-france);
  --form-background: var(--white);
  --form-disabled-text: var(--prussian-blue-30);
  --form-disabled-background: #f4f7fa;

  /*link*/
  --link-color: var(--blue-de-france);
}

/*Typography*/

h1 {
  font-size: 2.5rem;
  line-height: 3.75rem;
}

h2 {
  font-size: 2.125rem;
  line-height: 3.1875rem;
}

h3 {
  font-size: 1.875rem;
  line-height: 2.8125rem;
}

h4 {
  font-size: 1.625rem;
  line-height: 2.4375rem;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
}

.subtitle {
  font-size: 1.375rem;
  line-height: 2.0625rem;
  font-weight: 600;
}

.body-large {
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-weight: 400;
}

.body-medium-1 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.body-medium-2 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.body-small-1 {
  font-size: 0.9375rem;
  line-height: 1.40625rem;
  font-weight: 400;
}

.body-small-2 {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 600;
}

.body-extra-small-1 {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
}

.body-extra-small-2 {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
}

.button-large {
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-weight: 600;
}

.button-small {
  font-size: 0.8125rem;
  line-height: 1.21875rem;
  font-weight: 600;
}

.link {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 400;
}

.subtitle,
.body-large,
.body-medium-1,
.body-medium-2,
.body-small-1,
.body-extra-small-1,
.body-extra-small-2,
.button-large,
.button-small {
  font-family: "Inter", sans-serif;
}

/*END OF STYLE GUIDE*/

.text-color-white {
  color: var(--white);
}

.margin-top-15 {
  margin-top: 0.9375rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "ClanOT-Medium";
  src: local("ClanOTMedium"),
    url("./assests/fonts/ClanOTMedium.ttf") format("truetype");
}

@font-face {
  font-family: "ClanOT-NarrowMedium";
  src: local("ClanOT-NarrowMedium"),
    url("./assests/fonts/ClanOTNarrowMedium.ttf") format("truetype");
}

@font-face {
  font-family: "ClanOT-NarrowNews";
  src: local("ClanOT-NarrowNews"),
    url("./assests/fonts/ClanOTNarrowNews.ttf") format("truetype");
}

* {
  font-family: "Inter", sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.scroll {
}
