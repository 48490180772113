.preferred-goal {
  background: #ffffff;
  border: 2px solid #2288ee;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 51, 102, 0.1);
  border-radius: 10px;
}

.other-goal {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 51, 102, 0.1);
  border-radius: 10px;
}

.finalize-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 30px;

  background: #003366;
  border-radius: 10px;
}
