.input-box {
  background: #ffffff;
  border: 1px solid #d8d7dc;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  padding: 0.5rem;
  letter-spacing: 0.01em;
}
