.price-button {
  background-color: var(--blue-de-france-10);
  color: var(--prussian-blue);
  height: 3rem;
  padding: 0.8125rem 1.875rem;
  border-radius: 0.625rem;
}

.price-button:hover {
  color: var(--secondary-button-on-hover-text);
  background-color: var(--secondary-button-on-hover-background);
}

.price-button-secondary {
  background-color: var(--prussian-blue);
  color: var(--blue-de-france-10);
  height: 3rem;
  padding: 0.8125rem 1.875rem;
  border-radius: 0.625rem;
}

.price-button-secondary:hover {
  color: var(--primary-button-on-hover-text);
  background-color: var(--primary-button-on-hover-background);
}
