.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--prussian-blue);
}

.header_logo {
  /* height: 125px; */
  /* width: 125px; */
  padding: 10px;
}

.header_button_group {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
}

.header_button {
  color: white;
  background-color: var(--prussian-blue);
  padding: 20px;
  font-size: 20px;
  font-family: "ClanOT-NarrowNews";
  border-width: 0;
  border-radius: 10px;
}

.header_main_button {
  background-color: #bbeeff;
  color: black;
}
