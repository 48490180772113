.margin-top-22 {
    margin-top: 1.375rem;
}

.social-login-div {
    display:flex;
    /* justify-content:center;
    align-items: center; */

}

.social-login {
    border: 1px solid var(--form-border);
    box-sizing: border-box;
    border-radius: 8px;
    width: 6.4375rem;
    height: 3rem;
    display:flex;
    justify-content:center; 
    align-items: center;
}

.social-login:hover {
    border: 1px solid var(--form-border-on-hover);
    cursor: pointer;
}

.hr-150 {
    width: 9.375rem;
}