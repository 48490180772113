.title {
  font-size: 4rem;
}

.subtitle {
  font-size: 1.5rem;
}

.highlight {
  color: var(--main-button-color);
}
