.option {
  padding: "20px";
  width: "30%";
  background-color: "#BBEEFF";
  border-radius: "20px";
  font-size: "15px";
}

.font-f {
  font-family: "ClanOT-NarrowMedium";
}

.button {
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: #448899;
  border-radius: 20px;
  border-width: 0px;
  color: white;
  font-size: 20px;
}

.quarter-circle-top-right {
  width: 100px;
  height: 100px;
  background: blue;
  border-radius: 0 0 0 100px;
  -moz-border-radius: 0 0 0 100px;
  -webkit-border-radius: 0 0 0 100px;
  float: right;
}

.quarter-circle-bottom-left {
  width: 100px;
  height: 100px;
  background: green;
  border-radius: 0 100px 0 0;
  -moz-border-radius: 0 100px 0 0;
  -webkit-border-radius: 0 100px 0 0;
  float: left;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
