.program-div {
  display: flex;
  width: 20.375rem;
  height: 22.4375rem;
  flex-direction: column;
}

.program-div:hover {
  box-shadow: 0px 10px 17px rgba(0, 51, 102, 0.1);
  cursor: pointer;
  border-radius: 0rem 0rem 1.25rem 1.25rem;
}

.program-image {
  height: 18.0625rem;
  background-color: var(--primary-button-background);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  overflow: hidden;
}

.program-name {
  height: 4.375rem;
  color: var(--prussian-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-button-text);
  border-radius: 0rem 0rem 1.25rem 1.25rem;
}
