.primary {
  color: var(--primary-button-text);
  background-color: var(--primary-button-background);
}

.primary:hover {
  color: var(--primary-button-on-hover-text);
  background-color: var(--primary-button-on-hover-background);
}

.primary:disabled {
  color: var(--primary-button-disabled-text);
  background-color: var(--primary-button-disabled-background);
}

.secondary {
  color: var(--secondary-button-text);
  background-color: var(--secondary-button-background);
}

.secondary:hover {
  color: var(--secondary-button-on-hover-text);
  background-color: var(--secondary-button-on-hover-background);
}

.secondary:disabled {
  color: var(--secondary-button-disabled-text);
  background-color: var(--secondary-button-disabled-background);
}

.extra-large {
  height: 4rem;
  padding: 0.8125rem, 1.875rem;
  width: 10rem;
}

.large {
  height: 3rem;
  padding: 0.8125rem, 1.875rem;
}

.medium {
  height: 2.75rem;
  padding: 0.6875rem, 1.875rem;
}

.small {
  width: 5.1875rem;
  height: 2rem;
  padding: 0.375rem, 1.25rem;
}

.large,
.medium {
  width: 6.875rem;
}

.extra-large,
.large,
.medium,
.small {
  border-radius: 0.625rem;
}
