.program-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
}

.program-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.program-modal-desc{
    margin: 2rem 2rem 0rem 2rem;
}